<template>
  <v-main>
    <div class="main-content">
    <Card class="sticky-filters card-custom" v-on:keyup.enter="applyFilters">
      <template #title>
        <Toolbar>
          <template #start>
            <div class="d-flex flex-column">
              <h3 class="text-left">{{ $i18n.t('search') }}
                {{ $i18n.t('found') }}: {{ players.total ? players.total : 0 }}</h3>
                <div class="legend">
                  <div class="d-flex align-center">
                    <div 
                    class="bg-flamingo border-1 mini-square"
                    ></div>
                    <div class="ml-1">- {{ $i18n.t('players_from_agent') }}</div>
                  </div>
                  <div class="d-flex align-center">
                    <div 
                    class="bg-green border-1 mini-square"
                    ></div>
                    <div class="ml-1">- {{ $i18n.t('players_with_deposit') }}</div>
                  </div>
                  <div class="d-flex align-center">
                    <div 
                    style="opacity: .2;"
                    class="bg-black border-1 mini-square"
                    ></div>
                    <div class="ml-1">- {{ $i18n.t('players_without_deposit') }}</div>
                  </div>
                  <div class="d-flex align-center">
                    <div 
                    class="bg-yellow border-1 mini-square"
                    ></div>
                    <div class="ml-1">- {{ $i18n.t('players_have_multiaccs') }}</div>
                  </div>
                  <div class="d-flex align-center">
                    <div 
                    class="bg-red border-1 mini-square"
                    ></div>
                    <div class="ml-1">- {{ $i18n.t('players_pending_verification') }}</div>
                  </div>
                </div>
            </div>
          </template>
          <template #end>
            <!-- <div class="field-checkbox d-flex align-center mr-1">
              <Checkbox id="is-agent" @change="applyFilters" v-model="filters.is_agent_user" :binary="true" />
              <label for="is-agent" class="ml-1">{{ $t('only_agent_players') }}</label>
            </div> -->
              <div class="filters-column">
                <Dropdown v-model="filters.is_agent_user" 
              :options="typeUsers"
              @change="applyFilters"
              :placeholder="$t('type_users')" 
              optionValue="value"
               optionLabel="text" 
              :emptyFilterMessage="$t('no_data')">
            </Dropdown>
                <v-menu v-model="moreMenu" :close-on-content-click="false" transition="scale-transition" offset-y
                  nudge-left="0" nudge-right="0">
                  <template v-slot:activator="{ on }">
                    <span v-on="on" class="p-float-label p-input-icon-right">
                      <i class="pi pi-filter" />
                      <InputText
                      style="width: 100px;"
                      readonly class="p-inputtext-sm" :placeholder="$t('more')">
                      </InputText>
                    </span>
                  </template>
                  <div class="input-menu__menu text-left" v-on:keyup.enter="applyFilters">
                    <InputText type="text" @input="debounceFilters" v-model="filters.username" class="p-inputtext-sm"
                      :placeholder="$t('username')" />
                    <InputText type="text" @input="debounceFilters" v-model="filters.name" class="p-inputtext-sm" :placeholder="$t('name')" />
                    <InputText type="text" @input="debounceFilters" v-model="filters.surname" class="p-inputtext-sm" :placeholder="$t('surname')" />
                    <InputText type="text" @input="debounceFilters" v-model="filters.email" class="p-inputtext-sm" :placeholder="$t('email')" />
                    <InputText type="text" @input="debounceFilters" v-model="filters.tel" class="p-inputtext-sm" :placeholder="$t('phone')" />
                    <!-- <InputText type="text" @input="debounceFilters" v-model="filters.affiliate" class="p-inputtext-sm"
                      :placeholder="$t('affiliate')" /> -->
                  </div>
      
                </v-menu>
                <span class="p-buttonset">
                  <Button @click="clearFilters" :disabled="loadingFlag" :loading="loadingFlag" :label="$t('clear')"
                    icon="pi pi-trash" class="p-button-sm p-button-danger" />
                    <Button @click="applyFilters" :disabled="loadingFlag" :loading="loadingFlag" :label="$t('search')"
                    icon="pi pi-search" class="p-button-sm p-button-success" />
                  </span>
                  <Button @click="toAddDialog = true" :disabled="loadingFlag" :loading="loadingFlag"
                   :label="$t('add_new_player')"
                    icon="pi pi-plus" class="ml-1 p-button-sm p-button-success" />
              </div>
              
            </template>
      </Toolbar>
        
            <v-chip-group>
              <Chip class="active-filter-chip mr-1" v-for="(value, key) in filteredActiveFilters" 
              :key="'filter' + key"
                >
                <template>
                  {{ getFilterValueTitle(key) }}: {{ getFilterValueText(key, value) }}
                </template>
                <v-icon @click.stop="removeFilter(key, value)">mdi-close</v-icon>
              </Chip>
            </v-chip-group>
      </template>
      <template #content>
        <!-- <div class="ctn-table">
          <div class="ctn-content"> -->
            <DataTable 
            :value="players.items"
            :rows="players.size" 
            :loading="loadingFlag" 
            responsiveLayout="scroll"
              :filters.sync="filters" filterDisplay="row" :scrollable="true" 
              scrollHeight="flex"
              :rowClass="toGetAgentPlayers"
              stripedRows
              class="p-datatable-sm"
              removableSort
              >
              <template #empty>
                {{ $t('no_data') }}
              </template>
            <!-- bodyClass="justify-end" -->
              <Column  field="is_online" :showFilterMenu="false" :styles="{ 'width': '5rem', 'padding-left' : '8px' }">
                <template #header>
                  {{ $t('online') }}  
                  <Badge value="?" severity="info" class="info-badge ml-1" v-tooltip.right="$t('online_hint')"></Badge>
                </template>
                <template #body="{data}">
                    <div class="online-circle" :class="{ 'is-online' : data.is_online }">
      
                    </div>
                </template>
                <template #filter>
                  <div class="field-checkbox d-flex align-center">
                    <Checkbox id="binary" @change="applyFilters" v-model="filters.is_online" :binary="true" />
                    <!-- <label for="binary" class="ml-1">{{ $t('online') }}</label> -->
                  </div>
                </template>
              </Column>
              <Column sortable field="country_code" :showFilterMenu="false" :styles="{ 'min-width': '9rem' }">
                <template #header>
                  {{$t('country')}} 
                  <Badge value="?" severity="info" class="info-badge ml-1" v-tooltip.top="$t('country_hint')"></Badge>
                </template>
                <template #body="{data}">
                    {{ data.country_code  }}
                </template>
                <template #filter>
                  <InputText type="text"
                  @input="debounceFilters"
                   v-model="filters.country_code" class="p-inputtext-sm"
                    :placeholder="$t('country')" />
                </template>
              </Column>
              <!-- headerClass="header-frozen"
              alignFrozen="left"
              :frozen="true" -->
              <Column sortable field="id" 
              :showFilterMenu="false" :styles="{ 'min-width': '12rem',  }">
                <template #header>
                  ID 
                  <Badge value="?" severity="info" class="info-badge ml-1" v-tooltip.top="$t('id_hint')"></Badge>
                </template>
                <template #body="{data}">
                  <router-link class="c-main-flamingo" :to="`player/${data.id}`">
                    {{ data.id }} 
                  </router-link>
                  <span class="ml-1"> ({{ data.username }})</span>
                </template>
                <template #filter>
                  <InputText type="text"
                  @input="debounceFilters"
                   v-model="filters.id[0]" class="p-inputtext-sm"
                    placeholder="ID" />
                </template>
              </Column>
              <Column sortable field="total_deposit_amount" :showFilterMenu="false"
              :styles="{ 'min-width': '10rem' }">
              <template #header>
                {{ $t('deposits') }}  
                <Badge value="?" severity="info" class="info-badge ml-1" v-tooltip.top="$t('deposits_hint')"></Badge>
              </template>
                <template #body="{ data }">
                  <div class="d-flex align-center">
                    <div class="mr-1">
                        <div v-if="data.total_deposit_amount > 0" class="bg-green mini-square"></div>
                        <div v-else class="bg-black mini-square"
                        style="opacity: .2"
                        ></div>
                    </div>
                    <div class="d-flex align-center">
        
                      <span>
                        {{ data.total_deposit_amount.toLocaleString() }}
                      </span>
                      <span class="ml-1">
                        {{ data.currency }}
                      </span>
                    </div>
                  </div>
                </template>
                <template #filter>
                  <div class="field-checkbox">
                    <Checkbox id="binary" @change="applyFilters" v-model="filters.has_deposit" :binary="true" />
                    <!-- <label for="binary" class="ml-1">{{ $t('with_deposits') }}</label> -->
                  </div>
                </template>
              </Column>
              <Column sortable field="balance" :showFilterMenu="false" :styles="{ 'min-width': '10rem' }">
                <template #header>
                  {{ $t('balance') }}  
                  <Badge value="?" severity="info" class="info-badge ml-1" v-tooltip.top="$t('balance_hint')"></Badge>
                </template>
                <template #body="{ data }">
                  <div class="d-flex align-center">
                    <span>{{ data.balance.toLocaleString() }} </span>
                    <span class="ml-1">{{ data.currency }}</span>
                  </div>
                </template>
                <template #filter>
                  <v-menu v-model="balanceMenu" :close-on-content-click="false" transition="scale-transition" offset-y
                    nudge-left="0" nudge-right="0">
                    <template v-slot:activator="{ on }">
                      <span v-on="on" class="p-float-label p-input-icon-right">
                        <i class="pi pi-money-bill" />
                        <InputText type="text" readonly class="p-inputtext-sm pr-8" :placeholder="
                        getBalancePlaceholder
                        ">
                        </InputText>
                      </span>
                    </template>
                    <div class="input-menu__menu">
                      <InputText type="text" @input="debounceFilters" v-model="filters.min_balance" class="p-inputtext-sm"
                        :placeholder="$t('balance_from')" />
                      <InputText type="text" @input="debounceFilters" v-model="filters.max_balance" class="p-inputtext-sm"
                        :placeholder="$t('balance_to')" />
                    </div>
        
                  </v-menu>
                </template>
              </Column>
              <Column
              sortable
              field="ggr"
              :showFilterMenu="false"
              :styles="{ 'min-width': '10rem' }"
            >
              <template #header>
                {{ $t("ggr") }}
              </template>
              <template #body="{ data }">
                <span v-if="data.ggr" class="mx-1 flex-1 text-center"
                  >{{ data.ggr }} {{ data.currency }}</span
                >
              </template>
              <template #filter>
            </template>
            </Column>
              <Column sortable field="total_withdraw_amounts" :showFilterMenu="false"
                :styles="{ 'min-width': '12rem' }">
                <template #header>
                  {{ $t('withdrawals') }}  
                  <Badge value="?" severity="info" class="info-badge ml-1" v-tooltip.top="$t('withdrawals_hint')"></Badge>
                </template>
                <template #body="{ data }">
                  <div class="d-flex align-center">
                    <span>{{ data.total_withdraw_amounts.toLocaleString() }} </span>
                    <span class="ml-1">{{ data.currency }}</span>
                  </div>
                </template>
                <template #filter>
                  <v-menu v-model="withdrawalsMenu" :close-on-content-click="false" transition="scale-transition" offset-y
                    nudge-left="0" nudge-right="0">
                    <template v-slot:activator="{ on }">
                      <span v-on="on" class="p-float-label p-input-icon-right">
                        <i class="pi pi-money-bill" />
                        <InputText type="text" readonly class="p-inputtext-sm pr-8"
                          :placeholder="getWithdrawPlaceholder">
                        </InputText>
                      </span>
                    </template>
                    <div class="input-menu__menu">
                      <InputText type="text" @input="debounceFilters" v-model="filters.min_withdrawal"
                        class="p-inputtext-sm" :placeholder="$t('amount_from')" />
                      <InputText type="text" @input="debounceFilters" v-model="filters.max_withdrawal"
                        class="p-inputtext-sm" :placeholder="$t('amount_to')" />
                    </div>
        
                  </v-menu>
                </template>
              </Column>
              <Column sortable field="created_at" :showFilterMenu="false" :styles="{ 'min-width': '12rem' }">
                <template #header>
                  {{ $t('registered') }}  
                  <Badge value="?" severity="info" class="info-badge ml-1" v-tooltip.top="$t('registered_hint')"></Badge>
                </template>
                <template #body="{ data }">
                  <div style="white-space: nowrap">
                    {{ new Date(data.created_at).toLocaleString() }}
                  </div>
                </template>
                <template #filter>
                    <v-menu 
                    v-model="dateMenu" :close-on-content-click="false" transition="scale-transition" offset-y>
                      <template v-slot:activator="{ on }">
          
                        <InputText v-on="on" type="text" readonly class="p-inputtext-sm" :placeholder="$t('date')"
                          v-model="getFilteredDates">
                        </InputText>
          
                      </template>
                      <Calendar class="calendar-menu" :placeholder="$t('date')" :inline="true" :maxDate="today" id="icon"
                        dateFormat="mm.dd.yy" v-model="datesToFilter" selectionMode="range" />
                    </v-menu>
                </template>
              </Column>
              <Column sortable field="block" :showFilterMenu="false" :styles="{ 'min-width': '13rem' }">
                <template #header>
                  {{ $t('account_status') }}  
                  <Badge value="?" severity="info" class="info-badge ml-1" v-tooltip.top="$t('account_status_hint')"></Badge>
                </template>
                <template #body="{ data }">
                  <div class="d-flex align-center">
                    <!-- <v-chip :color="getChipBackgroundColor(data.block, data.block_end)" style="color: var(--white)" label small>
                      {{ getAccountStatus(data.block, data.block_end) }}
                    </v-chip> -->
                    <span class="custom-badge"
                :class="getAccountStatusClass(data.block, data.block_end)"
                >
                  <span>{{ getAccountStatus(data.block, data.block_end) }} </span>
                  <span v-if="!data.block && data.block_end">({{ formatDate(data.block_end) }})</span>
                </span>

                    <span v-if="data.processing" class="custom-badge _red ml-1"
                >
                  <span>{{ $t('withd_blocked') }} </span>
                </span>
                  </div>
                </template>
                <template #filter>
                  <!-- <Dropdown @keydown.enter="applyFilters" v-model="selectedOption" @change="handleChange($event)"
                  optionLabel="text" :placeholder="$t('method')" :options="options" :emptyFilterMessage="$t('no_data')">
                </Dropdown> -->
                <!-- class="under-header" -->
                <div id="select-option"
                
                >
                <!-- attach="#select-option" -->
                  <v-menu v-model="optionMenu" 
                  offset-y
                  :close-on-content-click="false" transition="scale-transition"
                      nudge-left="0" nudge-right="0">
                      <template v-slot:activator="{ on }">
                       
                          <span class="p-input-icon-right" v-on="on" >
                            <i class="pi pi-angle-down" />
                            <InputText 
                            :value="selectedOption && selectedOption.text ? selectedOption.text : ''"
                            type="text" readonly class="p-inputtext-sm" :placeholder="$t('method')">
                            </InputText>
                          </span>
                      </template>
                      <!-- <div class="input-menu__menu">
                      </div> -->
                        <Listbox v-model="selectedOption" :options="options"
                        @change="handleChange($event)"
                        :emptyFilterMessage="$t('no_data')"
                        optionLabel="text" style="width:15rem" />
          
                    </v-menu>
                </div>
              </template>
              </Column>
              <Column sortable field="level.name" :showFilterMenu="false" :styles="{ 'min-width': '8rem' }">
                <template #header>
                  {{ $t('level') }}  
                  <Badge value="?" severity="info" class="info-badge ml-1" v-tooltip.top="$t('player_status_hint')"></Badge>
                </template>
                <template #filter>
                  <div class="d-flex align-center">
                    <InputText type="text" @input="debounceFilters" v-model="filters.lvl_name"
                      class="p-inputtext-sm mb-0 mr-1" :placeholder="$t('level')" />
                    <!-- <InputText type="number" @keydown.enter="applyFilters" v-model="filters.max_status" class="p-inputtext-sm"
                      :placeholder="$t('max')" /> -->
                  </div>
                </template>
              </Column>
              <Column sortable field="pending_verification" :styles="{ 'min-width': '12rem' }" :showFilterMenu="false">
                <template #header>
                  {{ $t('pending_verification') }}  
                  <Badge value="?" severity="info" class="info-badge ml-1" v-tooltip.left="$t('pending_verification_hint')"></Badge>
                </template>
                <template #body="{ data }">
                  <div v-if="data.pending_verification === true" class="bg-red mini-square"></div>
                </template>
                <template #filter>
                  <div class="field-checkbox d-flex align-center">
                    <Checkbox id="binary" @change="applyFilters" v-model="filters.pending_verification" :binary="true" />
                    <!-- <label for="binary" class="ml-1">{{ $t('pending_verification') }}</label> -->
                  </div>
                </template>
              </Column>
              <Column sortable field="multiaccs" :showFilterMenu="false" :styles="{ 'min-width': '8rem' }">
                <template #header>
                  {{ $t('multi_accounts') }}  
                  <Badge value="?" severity="info" class="info-badge ml-1" v-tooltip.top="$t('multi_accounts_hint')"></Badge>
                </template>
                <template #body="{ data }">
                  <div v-if="data.multiaccs >= 1">
                    <div class="bg-yellow mini-square"></div>
                  </div>
                </template>
                <template #filter>
                  <div class="field-checkbox">
                    <Checkbox id="binary" @change="applyFilters" v-model="filters.has_multiaccs" :binary="true" />
                    <!-- <label for="binary" class="ml-1">{{ $t('multi_accounts') }}</label> -->
                  </div>
                </template>
              </Column>
            </DataTable>
          <!-- </div>
        </div> -->
      </template>
    </Card>
    <games-paggination 
                        :pagginationObject="filters" 
                        :requestData="players" 
                        @apply-filters="setAndSubmitFilters" 
                        :isStatic="false"
                        />
    <add-new-player 
              @filter-new-player="filterNewPlayer"
              @added="toAddDialog = false"
              @dialog="toAddDialog = false"
              :toAddDialog="toAddDialog" />
    <ScrollTopButton></ScrollTopButton>
  </div>
  </v-main>
</template>


<script>
import { debounce } from 'lodash'
import AddNewPlayer from "./AddNewPlayer/AddNewPlayer";
import { mapGetters } from "vuex";
import ScrollTopButton from "@/components/ScrollTopButton";
import GamesPaggination from '@/views/GamesPaggination'
export default {
  name: 'Players',
  components: { 
    GamesPaggination,
    ScrollTopButton,
    AddNewPlayer,
  },
  computed: {
    ...mapGetters({
      players: 'getPlayers',
      gameFilters: 'paggination/getFilters',
      multiaccs: 'getMultiaccs',
    },
    ),
    getBalancePlaceholder() {
      const { min_balance, max_balance } = this.filters;
      if (min_balance && max_balance) {
        
      
      const trimMinBalance = min_balance.trim();
      const trimMaxBalance = max_balance.trim();
        if (!trimMinBalance && !trimMaxBalance) {
          return this.$t('amount');
        } else if (!trimMinBalance) {
          return this.$t('to') + ' ' + trimMaxBalance;
        } else if (!trimMaxBalance) {
          return this.$t('from') + ' ' + trimMinBalance;
        } else if(trimMinBalance && trimMaxBalance) {
          return `${this.$t('from')} ${trimMinBalance} ${this.$t('to')} ${trimMaxBalance}`
        } else {
          return this.$t('amount');
        }
      } else{
        return this.$t('amount')
      }
    },
    getWithdrawPlaceholder() {
      const { min_withdrawal, max_withdrawal } = this.filters;
      if (min_withdrawal && max_withdrawal) {
      const trimMinBalance = min_withdrawal.trim();
      const trimMaxBalance = max_withdrawal.trim();
        if (!trimMinBalance && !trimMaxBalance) {
          return this.$t('amount');
        } else if (!trimMinBalance) {
          return this.$t('to') + ' ' + trimMaxBalance;
        } else if (!trimMaxBalance) {
          return this.$t('from') + ' ' + trimMinBalance;
        } else if(trimMinBalance && trimMaxBalance) {
          return `${this.$t('from')} ${trimMinBalance} ${this.$t('to')} ${trimMaxBalance}`
        } else {
          return this.$t('amount');
        }
      } else{
        return this.$t('amount')
      }
    },
    getFilteredDates(){
      if (this.datesToFilter.length > 0) {
        let one;
        let two;
          const formattedDates = this.datesToFilter.map(dateStr => {
            const date = new Date(dateStr);
            return date.toLocaleDateString().split('T')[0];
          });
          const formattedDateOne = formattedDates[0];
          const formattedDateTwo = formattedDates[1];
          if (this.datesToFilter.length === 2 && this.datesToFilter[1]) {
            // both data
            one = formattedDateOne;
            two = formattedDateTwo;
            return `${one} - ${two}`
          } else {
            // only one
            one = formattedDateOne;
            return `${one} - ${one}`
          }
        } else{
          return ''
        }
    },
    filteredActiveFilters() {
      const activeFilters = Object.entries(this.activeFilters).filter(
    ([key, value]) => key !== 'size' && key !== 'page' 
    && (key === 'is_active' || value !== false) 
    && value !== [] && value !== null
  );

  const activeFiltersObject = Object.fromEntries(activeFilters);

  if (Array.isArray(activeFiltersObject.id)) {
  activeFiltersObject.id.forEach((id, index) => {
    activeFiltersObject[`ID_${index + 1}`] = id;
  });
  delete activeFiltersObject.id; 
}

  return activeFiltersObject;
    },
  },
  data() {
    return {
      toAddDialog: false,
      loadingFlag: false,
      currentLocale: this.$i18n.locale || 'en',
      loadingModalFlag: false,
      optionMenu: false,
      dateMenu: false,
      balanceMenu: false,
      withdrawalsMenu: false,
      moreMenu: false,
      today: new Date(),
      datesToFilter: [],
      typeUsers: [
        { value: '', text: this.$t('all_players') },
        { value: true, text: this.$t('agents') },
        { value: false, text: this.$t('platform') },
      ],
      options: [
        { value: 'active', text: this.$i18n.t('active') },
        { value: 'forever_blocked', text: this.$i18n.t('forever_blocked') },
        { value: 'temp_blocked', text: this.$i18n.t('temp_blocked') },
        { value: 'blocked_withdraw', text: this.$i18n.t('withdraw_blocked') },
      ],
      selectedOption: null,
      filters: {
        affiliate_id: '',
        is_active: '',
        //is_online: null,
        //pending_verification: null,
        //has_multiaccs: null,
        //has_deposit: null,
        is_agent_user: '',
        min_balance: '',
        max_balance: '',
        min_withdrawal: '',
        max_withdrawal: '',
        min_reg_date: '',
        max_reg_date: '',
        lvl_name: '',
        id: [],
        username: '',
        surname: '',
        email: '',
        name: '',
        comment: '',
        tel: '',
        country_code: '',
        temp_blocked: '',
        blocked_withdraw: '',
      },
      startPage: 1,
      startSize: 25,
      activeFilters: {},
    }
  },
  watch: {
    datesToFilter(newValue, oldValue){
            if (newValue[0] && newValue[1]) {
              this.applyFilters()
            }
          },
          '$route.params.online': {
            handler(newV){
              if (newV) {
                this.filters.is_online = newV;
                this.applyFilters();
              }
            },
            immediate: true,
          },
    '$route.params.users_ids': {
      handler(newUserIds) {
        // console.log('$route.params.users_ids', this.$route.params.users_ids);
        // if (newUserIds) {
          
        //   let userIdsArray = [];
        //   userIdsArray.forEach(id => {
        //   if (!this.filters.id.includes(id)) {
        //     this.filters.id.push(id);
        //   }
        // });
        // }
      },
      immediate: true,
      deep: true
    },
    multiaccs: {
      handler(newUserIds) {
        // console.log('newUserIds', newUserIds.multiaccs);
        if (newUserIds.multiaccs) {
          // let userIdsArray = [];
          this.filters.id = [];
          newUserIds.multiaccs.forEach(item => {
            // console.log('item', item);
          if (!this.filters.id.includes(item.id)) {
            this.filters.id.push(item.id);
          }
          });
        this.setAndSubmitFilters();
        }
      },
      immediate: true,
      deep: true
    },
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.$store.commit('setPageName', 'players_list')
    });
  },
  async mounted() {
    await this.applyFilters();
  },
  methods: {
    debounceFilters: debounce(async function() {
      await this.applyFilters();
    }, 750),
    toGetAgentPlayers(rowData) {
      return rowData.agent_id ? 'bg-flamingo' : '';
    // console.log('rowData', rowData);    
    },
    async handleChange(event) {
      if (!event.value) {
        this.filters.blocked_withdraw = false;
        this.filters.temp_blocked = false;
        this.filters.is_active = '';
        this.optionMenu = false;
        await this.applyFilters();
        return
      }
      if (event.value.value === 'active') {
        this.filters.is_active = true;
        this.filters.temp_blocked = false;
        this.filters.blocked_withdraw = false;
        console.log(this.filters.is_active);
      } else if (event.value.value === 'forever_blocked') {
        this.filters.is_active = false;
      } else if (event.value.value === 'temp_blocked') {
        this.filters.temp_blocked = true;
        this.filters.blocked_withdraw = false;
        this.filters.is_active = '';
      } else if (event.value.value === 'blocked_withdraw') {
        this.filters.blocked_withdraw = true;
        this.filters.temp_blocked = false;
        this.filters.is_active = '';
      }
      this.optionMenu = false;
      await this.applyFilters();
    },
    formatDate(date) {
      if (!(date instanceof Date)) {
        date = new Date(date);
      }
      return date.toLocaleDateString();
    },
    getFilterValueTitle(key) {
      // chip keys
      switch (key) {
        case 'is_agent_user':
          return this.$t('agent')
        case 'is_active':
          return this.$t('status')
        case 'username':
          return this.$t('username')
        case 'surname':
          return this.$t('surname')
        case 'min_balance':
          return this.$t('balance_from')
        case 'max_balance':
          return this.$t('balance_to')
        case 'id':
          return 'id'
        case 'online':
          return this.$t('online')
        case 'min_reg_date':
          return this.$t('min_reg_date')
        case 'max_reg_date':
          return this.$t('max_reg_date')
        case 'name':
          return this.$t('name')
        case 'email':
          return this.$t('email')
        case 'tel':
          return this.$t('tel_number')
        case 'lvl_name':
          return this.$t('player_status')
        case 'affiliate_id':
          return this.$t('affiliate')
        case 'pending_verification':
          return this.$t('pending_verification')
        case 'blocked_withdraw':
          return this.$t('withdraw_blocked')
        case 'temp_blocked':
          return this.$t('temp_blocked')
        case 'has_multiaccs':
          return this.$t('multi_accounts')
        case 'has_deposit':
          return this.$t('with_deposits')
        case 'is_online':
          return this.$t('online')
        case 'min_balance':
          return this.$t('withdrawals_from')
        case 'max_balance':
          return this.$t('withdrawals_to')
        case 'min_withdrawal':
          return this.$t('min_withdrawal')
        case 'max_withdrawal':
          return this.$t('min_withdrawal')
        case 'country_code':
          return this.$t('country_code')
      }

      return key;
    },
    getFilterValueText(key, value) {
      // chip values
      if (key === 'is_active' && value === true) {
        return this.$t('active')
      }
      if (key === 'is_active' && value === false) {
        return this.$t('forever_blocked')
      }
      switch (value) {
        case true:
          return this.$t('yes')
        case false:
          return this.$t('no')
      }

      return value;
    },

    getAccountStatus(block, blockEndDate) {
      const currentDate = new Date();
      const endDate = new Date(blockEndDate);

      if (block === 2) {
        return this.$i18n.t("forever_blocked");
      } else if (endDate > currentDate) {
        return this.$i18n.t("temp_blocked");
      } else if (block === 0) {
        return this.$i18n.t("active");
      } else {
        return "NONE";
      }
    },
    getAccountStatusClass(block, blockEndDate) {
      const currentDate = new Date();
      const endDate = new Date(blockEndDate);
      return block === 2
        ? "_red"
        : endDate > currentDate
        ? "_yellow"
        : block === 0
        ? "_green"
        : "_yellow";
    },
    playerBlockStatus(item) {
      switch (item.block) {
        case 0:
          return this.$i18n.t('active');
        case 1:
          return this.$i18n.t('temporary_blocked') + `${new Date(item.blockEnd).toLocaleString()}`;
        case 2:
          return this.$i18n.t('forever_blocked');
      }
      return '-';
    },
    statusColor(block) {
      switch (block) {
        case 0:
          return 'var(--green-success)';
        case 1:
          return '#ff9800';
        case 2:
          return 'var(--red)';
        default:
          return '';
      }
    },
    async setAndSubmitFilters() {
  this.loadingFlag = true;

  const uniqueUserIds = [];
  this.filters.id.forEach(item => {
    if (!uniqueUserIds.includes(item)) {
      uniqueUserIds.push(item);
    }
  });

  const userIdsQueryString = uniqueUserIds.map(id => `id=${id}`).join('&');

  const otherFilters = Object.entries(this.filters)
    .filter(([key, value]) => key !== 'id' && value !== '' && value !== null)
    .map(([key, value]) => `${key}=${value}`)
    .join('&');
  const queryString = [userIdsQueryString, otherFilters].join('&');
  try {
    await this.$store.dispatch('playersFilters', queryString);
      // Обновляем activeFilters
      for (const [key, value] of Object.entries(this.filters)) {
        if (value !== '' && value !== null) {
          this.$set(this.activeFilters, key, value);
        } else {
          this.$delete(this.activeFilters, key);
        }
      }
  } catch (error) {
    this.$toast.add({ severity: 'error', summary: this.$t('just_error'), life: 4000 });
  } finally {
    this.$store.commit('paggination/SET_FILTERS', this.filters);
    this.loadingFlag = false;
    this.$store.commit('paggination/SET_LOADING_FLAG', false);
  }
},
    filterNewPlayer(username){
      this.filters.username = username;
      this.applyFilters();
    },
    async applyFilters() {
        this.filters.page = this.startPage;
        this.filters.size = this.startSize;
        this.getFormattedDate();
        this.$store.commit('paggination/SET_FILTERS', this.filters);
        this.setAndSubmitFilters();
    },
    getFormattedDate(){
      if (this.datesToFilter.length > 0) {
          const formattedDates = this.datesToFilter.map(dateStr => {
            const date = new Date(dateStr);
            return date.toISOString().split('T')[0];
          });
          const formattedDateOne = formattedDates[0];
          const formattedDateTwo = formattedDates[1];
          if (this.datesToFilter.length === 2) {
            // both data
            this.filters.min_reg_date = formattedDateOne;
            this.filters.max_reg_date = formattedDateTwo;
          } else if (this.datesToFilter.length === 1) {
            // only one
            this.filters.min_reg_date = formattedDateOne;
          }
        }
    },
    async clearFilters() {
      this.loadingFlag = true;
      Object.keys(this.filters).forEach((key, index) => {
        // this.filters[key] = ''
        this.$delete(this.filters, key);
      })
      Object.keys(this.activeFilters).forEach((key, index) => {
        this.$delete(this.activeFilters, key);
      })
      this.datesToFilter = [],
      // this.filters.is_online = false;
      // this.filters.pending_verification = false;
      // this.filters.has_multiaccs = false;
      // this.filters.has_deposit = false;
      this.filters.id = [];
      this.selectedOption = null;
      this.setAndSubmitFilters();
    },
    async removeFilter(key, value) {
      this.loadingFlag = true;
      // Delete chip
      if (key.includes('ID_')) {
        const index = this.activeFilters.id.indexOf(value);
  if (index > -1) {
    this.$delete(this.activeFilters.id, index); // 
  }
      } else{
        this.$delete(this.activeFilters, key);
      }
      // empty string
      if (typeof this.filters[key] === 'boolean') {
        this.$set(this.filters, key, false);
      } else {
        this.$set(this.filters, key, '');
      }
      // is_active
      if (key === 'is_active') {
        this.filters.is_active = '';
        this.selectedOption = null;
      }
      if (key === 'blocked_withdraw') {
        this.filters.blocked_withdraw = '';
        this.selectedOption = null;
      }
      if (key === 'temp_blocked') {
        this.filters.temp_blocked = '';
        this.selectedOption = null;
      }
      if (key === 'min_reg_date') {
        this.datesToFilter[0] = '';
      }
      if (key === 'max_reg_date') {
        this.datesToFilter[1] = '';
      }
      this.setAndSubmitFilters();
    },
  }
}
</script>

<style lang="scss" scoped>

.paggination {}

.margin-top-ad {
  @media (min-width:960px) {
    margin-top: 0px !important;
  }
}

.filtersCard {
  width: 100%;
  z-index: 3;
  position: -webkit-sticky;
  position: sticky;
  top: 63px;

}

.card_style {
  margin-top: 10px;
  box-shadow: 0 0 5px #2c3e50;
  border-radius: 20px !important;
}

.card_header_style {
  text-align: left;
  font-size: 26px;
  font-weight: 400;
  padding: 10px;
}

.card_body_style {
  background-color: #0f0f0f0f;

}

.label_style {
  text-align: left;
  font-weight: 100;
  font-size: 20px;
}

.label_style_center {
  font-weight: 100;
  font-size: 20px;
}

.modal_th {
  font-size: 20px;
}

@media screen and (max-width: 960px),
(max-height: 500px) and (orientation: landscape) {
  .filtersCard {
    position: static !important;
  }
}
</style>
