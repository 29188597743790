import Vue from "vue";
import VueRouter from "vue-router";
import Players from "../views/Players";
import Login from "../views/Login";
import FinancialTransactions from "../views/FinancialTransactions";
// import Games from "../views/Games";
import PayMethods from "../views/PayMethods";
import Support from "../views/Support";
import NoMobile from "../views/NoMobile";
import Cashflow from "../views/Cashflow";
import store from "../store/store";
import AffiliateInfo from "../views/AffiliateInfo";

import AllAuthSessions from "../views/AllAuthSessions";
import AdminAuthSessions from "../views/Authentications/AdminAuthSessions";
import AllGameFeeSetting from "../views/GameFeeSetting/AllGameFeeSetting";
import Redirector from "../views/Redirector";
// import routerGuard from './routerGuard';
Vue.use(VueRouter);
const routes = [
  {
    path: "/",
    redirect: { name: "Login" },
  },
  {
    path: "/agent-players",
    name: "agent_players",
    meta: {
      isAgent: true,
    },
    component: function () {
      return import(
        "@/components/Agents/AgentPlayersList/AgentPlayersList.vue"
      );
    },
  },
  {
    path: "/jackpot-settings",
    name: "jackpot_settings",
    meta: {
      isAgent: true,
    },
    component: function () {
      return import("@/components/Agents/Jackpots/JackpotSettings.vue");
    },
  },
  // {
  //   path: "/agents-rtp-report",
  //   name: "agents_rtp_report",
  //   meta: {
  //     isAgent: true,
  //   },
  //   component: function () {
  //     return import("@/components/Reports/AgentsAverageRTP.vue");
  //   },
  // },
  {
    path: "/agents-financial-report",
    name: "agents_financial_report",
    meta: {
      isAgent: true,
    },
    component: function () {
      return import("@/components/Reports/AgentsFinancialReport.vue");
    },
  },
  {
    path: "/agents",
    name: "agents",
    props: true,
    meta: {
      isAgent: true,
    },
    component: function () {
      return import("@/components/Agents/AgentsList/Agents.vue");
    },
  },
  {
    path: "/players",
    name: "players",
    props: true,
    component: function () {
      return import(/* webpackChunkName: "about" */ "../views/Players.vue");
    },
  },
  {
    path: "/segments",
    redirect: "/segments/view",
    name: "segments",
    props: true,
    component: function () {
      return import("../views/Segments/Segments.vue");
    },
    children: [
      {
        path: "view",
        name: "ViewSegments",
        component: function () {
          return import("../views/Segments/ViewSegments.vue");
        },
      },
      {
        path: "create",
        name: "CreateSegment",
        component: function () {
          return import("../views/Segments/CreateSegment.vue");
        },
      },
      {
        path: "edit/:id",
        name: "EditSegment",
        component: function () {
          return import("../views/Segments/EditSegment.vue");
        },
      },
    ],
  },
  {
    path: "/themes",
    redirect: "/themes/admin",
    name: "ThemeCustomize",
    component: function () {
      return import("@/components/CMS/ThemesCustomize/ThemeCustomizeView.vue");
    },
    // meta: {
    //   isAgentSettings: true,
    // },
    children: [
      {
        path: "admin",
        name: "ThemeCustomizeAdmin",
        // meta: {
        //   isAgentSettings: true,
        // },
        component: () =>
          import("@/components/CMS/ThemesCustomize/ThemeCustomize.vue"),
      },
      {
        path: "agent",
        name: "ThemeCustomizeAgent",
        // meta: {
        //   isAgentSettings: true,
        // },
        meta: {
          isAgentLevel: true,
        },
        component: () =>
          import("@/components/CMS/ThemesCustomize/ThemeCustomize.vue"),
      },
    ],
  },
  {
    path: "/multiacc-setup",
    name: "MultiaccSetup",
    component: function () {
      return import("@/components/MultiaccSetup/MultiaccSetup.vue");
    },
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: function () {
      return import("@/components/Dashboard/Dashboard.vue");
    },
    meta: { isEternal: true },
  },
  {
    path: "/player/:id",
    name: "player",
    component: function () {
      return import(
        /* webpackChunkName: "about" */ "../views/PlayerProfile/PlayerInfo.vue"
      );
    },
    props: true,
  },
  {
    path: "/banners",
    redirect: "/banners/main-slider",
    name: "BannersView",
    component: function () {
      return import("../components/CMS/Banners/BannersView.vue");
    },
    children: [
      {
        path: "main-slider",
        name: "MainSliderBanners",
        component: function () {
          return import("../components/CMS/Banners/MainSliderBanners.vue");
        },
      },
      {
        path: "user-reg",
        name: "UserRegBanner",
        component: function () {
          return import("../components/CMS/Banners/UserRegBanners.vue");
        },
      },
    ],
  },
  {
    path: "/avatars",
    name: "players_ava",
    redirect: "/avatars/view",
    component: function () {
      return import("../components/CMS/PlayerAvatars/PlayerAvatars");
    },
    children: [
      {
        path: "view",
        name: "ViewPlayerAvatars",
        meta: {
          parent: "players_ava",
        },
        component: () =>
          import("../components/CMS/PlayerAvatars/ViewPlayerAvatars.vue"),
      },
      {
        path: "create",
        name: "CreatePlayerAvatars",
        meta: {
          parent: "players_ava",
        },
        component: () =>
          import("../components/CMS/PlayerAvatars/CreatePlayerAvatars.vue"),
      },
      {
        path: "edit/:id",
        name: "EditPlayerAvatars",
        meta: {
          parent: "players_ava",
        },
        component: () =>
          import("../components/CMS/PlayerAvatars/EditPlayerAvatars.vue"),
      },
    ],
  },

  {
    path: "/localization",
    name: "Localizations",
    redirect: "/localization/admin/general",
    component: function () {
      return import("@/components/CMS/Local/LocalizationsView");
    },
    children: [
      {
        path: "admin",
        name: "LocalizationAdmin",
        redirect: "/localization/admin/general",
        component: function () {
          return import("@/components/CMS/Local/Localizations");
        },
        children: [
          {
            path: "general",
            name: "GeneralLocalizationAdmin",
            component: function () {
              return import("@/components/CMS/Local/GeneralLocalization");
            },
          },
          {
            path: "legal",
            name: "LegalLocalizationAdmin",
            component: function () {
              return import("@/components/CMS/Local/LegalLocalization");
            },
          },
        ],
      },
      {
        path: "agent",
        name: "LocalizationAgent",
        redirect: "/localization/agent/general",
        component: function () {
          return import("@/components/CMS/Local/Localizations");
        },

        children: [
          {
            path: "general",
            name: "GeneralLocalizationAgent",
            component: function () {
              return import("@/components/CMS/Local/GeneralLocalization");
            },
            meta: {
              isAgentLevel: true,
            },
          },
          // {
          //   path: "legal",
          //   name: "LegalLocalizationAgent",
          //   component: function () {
          //     return import("@/components/CMS/Local/LegalLocalization");
          //   },
          // },
        ],
      },
    ],
  },
  {
    path: "/custom-scripts",
    name: "CustomJs",
    redirect: "/custom-scripts/view",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(
        /* webpackChunkName: "about" */ "../components/CMS/CustomScripts/CustomScripts.vue"
      );
    },
    children: [
      {
        path: "view",
        name: "ViewCustomScripts",
        component: () =>
          import("../components/CMS/CustomScripts/ViewCustomScripts.vue"),
      },
      {
        path: "create",
        name: "CreateCustomScript",
        component: () =>
          import("../components/CMS/CustomScripts/CreateCustomScript.vue"),
      },
      {
        path: "edit/:id",
        name: "EditCustomScript",
        component: () =>
          import("../components/CMS/CustomScripts/EditCustomScript.vue"),
      },
    ],
  },
  {
    path: "/affiliates",
    name: "Affiliates",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(/* webpackChunkName: "about" */ "../views/Affiliates.vue");
    },
    meta: { isEternal: true },
  },
  {
    path: "/geo-restrictions",
    name: "Geo",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(
        /* webpackChunkName: "about" */ "../components/GeoRestrictions/GeoRestrictions.vue"
      );
    },
  },
  {
    path: "/support",
    name: "Support",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(/* webpackChunkName: "about" */ "../views/Support.vue");
    },
  },
  {
    path: "/game-settings",
    name: "GameSettings",
    component: function () {
      return import("@/views/GameSettings/GameSettings.vue");
    },
  },
  // {
  //   path: "/games",
  //   name: "Games",
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: function () {
  //     return import(/* webpackChunkName: "about" */ "../views/Games/Games.vue");
  //   },
  //   props: true,
  // },
  {
    path: "/game-manager",
    name: "game_manager",
    redirect: "/game-manager/providers",
    props: true,
    component: function () {
      return import("../views/Games/GameManager.vue");
    },
    children: [
      {
        path: "providers",
        name: "game_manager_providers",
        component: function () {
          return import("../views/Games/ByProvider/ByProvider.vue");
        },
        meta: {
          isAgentLevel: true,
        },
      },
      {
        path: "blocks",
        name: "game_manager_blocks",
        component: function () {
          return import("../views/Games/ByBlock/ByBlock.vue");
        },
      },
    ],
  },
  {
    path: "/paymethods",
    name: "PayMethods",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(/* webpackChunkName: "about" */ "../views/PayMethods.vue");
    },
  },
  {
    path: "/financial-transactions",
    name: "FinTransactions",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(
        /* webpackChunkName: "about" */ "../views/FinancialTransactions.vue"
      );
    },
  },
  {
    path: "/FinancialTransactions/:playerId/:paymentId",
    name: "PlayerTransaction",
    component: () => import("../views/PlayerTransaction.vue"),
    props: true,
  },
  {
    path: "/login",
    name: "Login",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(/* webpackChunkName: "about" */ "../views/Login.vue");
    },
    beforeEnter: (to, from, next) => {
      if (!to.query.tab) {
        next({ path: "/login", query: { tab: "0" } });
      } else {
        next();
      }
    },
  },
  {
    path: "/nomobile",
    name: "NoMobile",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(/* webpackChunkName: "about" */ "../views/NoMobile.vue");
    },
  },
  {
    path: "/cashflow",
    name: "Cashflow",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(/* webpackChunkName: "about" */ "../views/Cashflow.vue");
    },
  },
  {
    path: "/bonuses",
    name: "Bonuses",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(/* webpackChunkName: "about" */ "../views/Bonuses.vue");
    },
  },
  {
    path: "/deposit-bonuses",
    name: "DepositBonuses",
    redirect: "/deposit-bonuses/view",
    component: function () {
      return import(
        "../components/PlayerEngagement/Bonuses/DepositBonuses/DepositBonuses.vue"
      );
    },
    children: [
      {
        path: "view",
        name: "ViewDepositBonuses",
        component: () =>
          import(
            "../components/PlayerEngagement/Bonuses/DepositBonuses/ViewDepositBonuses.vue"
          ),
      },
      {
        path: "create",
        name: "CreateDepositBonus",
        component: () =>
          import(
            "../components/PlayerEngagement/Bonuses/DepositBonuses/CreateDepositBonus.vue"
          ),
      },
      {
        path: "edit/:id",
        name: "EditDepositBonus",
        component: () =>
          import(
            "../components/PlayerEngagement/Bonuses/DepositBonuses/EditDepositBonus.vue"
          ),
      },
    ],
  },
  {
    path: "/spins-bonuses",
    name: "SpinsBonuses",
    redirect: "/spins-bonuses/view",
    component: function () {
      return import(
        "../components/PlayerEngagement/Bonuses/SpinsBonus/SpinsBonuses.vue"
      );
    },
    children: [
      {
        path: "view",
        name: "ViewSpinBonuses",
        component: () =>
          import(
            "../components/PlayerEngagement/Bonuses/SpinsBonus/ViewSpinsBonuses.vue"
          ),
      },
      {
        path: "create",
        name: "CreateSpinsBonus",
        component: () =>
          import(
            "../components/PlayerEngagement/Bonuses/SpinsBonus/CreateSpinsBonus.vue"
          ),
      },
      {
        path: "edit/:id",
        name: "EditSpinsBonus",
        component: () =>
          import(
            "../components/PlayerEngagement/Bonuses/SpinsBonus/EditSpinsBonus.vue"
          ),
      },
    ],
  },
  {
    path: "/player-levels",
    name: "player_levels",
    redirect: "/player-levels/view",
    component: function () {
      return import(
        "../components/PlayerEngagement/PlayerLevels/PlayerLevels.vue"
      );
    },
    children: [
      {
        path: "view",
        name: "PlayerLevelsView",
        component: function () {
          return import(
            "../components/PlayerEngagement/PlayerLevels/PlayerLevelsView.vue"
          );
        },
      },
      {
        path: "create",
        name: "CreatePlayerLevel",
        component: function () {
          return import(
            "../components/PlayerEngagement/PlayerLevels/CreatePlayerLevel.vue"
          );
        },
      },
      {
        path: "edit/:id",
        name: "EditPlayerLevel",
        component: function () {
          return import(
            "../components/PlayerEngagement/PlayerLevels/EditPlayerLevel.vue"
          );
        },
      },
    ],
  },
  {
    path: "/role-admins",
    name: "RoleAdmins",
    redirect: "/role-admins/view",
    component: function () {
      return import("../components/AccessControl/RoleAdmins/RoleAdmins.vue");
    },
    children: [
      {
        path: "view",
        name: "ViewRoleAdmins",
        component: function () {
          return import(
            "../components/AccessControl/RoleAdmins/ViewRoleAdmins.vue"
          );
        },
      },
      {
        path: "add",
        name: "AddAdmin",
        component: function () {
          return import("../components/AccessControl/RoleAdmins/AddAdmin.vue");
        },
      },
      {
        path: "edit/:id",
        name: "EditAdmin",
        component: function () {
          return import("../components/AccessControl/RoleAdmins/EditAdmin.vue");
        },
      },
    ],
  },

  {
    path: "/role-settings",
    name: "RoleSettings",
    component: function () {
      return import("../components/AccessControl/RoleSettings.vue");
    },
  },
  {
    path: "/promocodes",
    name: "Promocodes",
    component: function () {
      return import("../components/PlayerEngagement/Promocodes/Promocodes.vue");
    },
  },
  {
    path: "/promocodes/create",
    name: "CreatePromocode",
    component: function () {
      return import(
        "../components/PlayerEngagement/Promocodes/CreatePromocode.vue"
      );
    },
  },
  // {
  //   path: '/reports',
  //   name: 'reports',
  //   redirect: '/reports/general',
  //   children: [
  //     {
  //       path: 'general',
  //       name: 'general',
  //       component: function () {
  //         return import('../components/Reports/GeneralReport.vue')
  //       }
  //     },
  //     {
  //       path: 'games',
  //       name: 'games',
  //       component: function () {
  //         return import('../components/Reports/GamesReport.vue')
  //       }
  //     },
  //   ]
  // },
  {
    path: "/general-report",
    name: "general_report",
    component: function () {
      return import("../components/Reports/GeneralReport.vue");
    },
  },
  {
    path: "/games-report",
    name: "games_report",
    component: function () {
      return import("../components/Reports/GamesReport.vue");
    },
    meta: {
      isAgent: true,
    },
  },
  {
    path: "/affiliate/:id",
    name: "AffiliateInfo",
    component: AffiliateInfo,
    props: true,
  },
  {
    path: "/auth",
    name: "AllAuthLogs",
    component: AllAuthSessions,
    meta: {
      isAgent: true,
    },
    props: true,
  },
  {
    path: "/authentications",
    redirect: "/authentications/admin",
    name: "AdminLogs",
    component: function () {
      return import("../views/Authentications/Authentications.vue");
    },
    props: true,
    children: [
      {
        path: "admin",
        name: "AdminAuthSessions",
        component: function () {
          return import("../views/Authentications/AdminAuthSessions.vue");
        },
      },
      {
        path: "agent",
        name: "AgentAuthSessions",
        component: function () {
          return import("../views/Authentications/AgentAuthSessions.vue");
        },
        meta: {
          isAgentLevel: true,
        },
      },
    ],
  },
  {
    path: "/game-fee-settings",
    redirect: "/game-fee-settings/providers",
    name: "GameFeeSettings",
    component: AllGameFeeSetting,
    children: [
      {
        path: "providers",
        name: "GameProviders",
        component: function () {
          return import("../views/GameFeeSetting/GameProviders.vue");
        },
      },
      {
        path: "aggregators",
        name: "GameAggregators",
        component: function () {
          return import("../views/GameFeeSetting/GameAggregators.vue");
        },
      },
    ],
  },
  {
    path: "/logout",
    component: Login,
  },
  {
    path: "/redirector",
    component: Redirector,
    meta: { isEternal: true },
  },
  {
    path: "*",
    redirect: "/dashboard",
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});
router.beforeEach(async (to, from, next) => {
  const jwt = localStorage.getItem("jwt");
  let tokenUser;
  let type;
  let admin;
  if (jwt) {
    type = await store.dispatch("getTokenUser");
    // console.log(store.state.roles.permissions);
    if (type === "agent") {
      // console.log("admin before", store.getters.admin);
      if (!store.getters.admin.id) {
        admin = await store.dispatch("getAgentStatus");
        // console.log("admin", admin);
      }
      tokenUser === "agent";
    } else {
      // console.log(type);
      if (!store.state.roles.permissions) {
        const res = await store.dispatch("roles/awaitGetPermissions");
        const perms = res.ui_permissions.reduce((acc, permission) => {
          return acc.concat(permission.section);
        }, []);
        tokenUser = perms;
      } else {
        // tokenUser = store.state.roles.permissions.ui_permissions[0].elements;
        const module = store.state.roles.permissions.ui_permissions;
        const perms = module.reduce((acc, permission) => {
          return acc.concat(permission.section);
        }, []);
        tokenUser = perms;
      }
    }
    // tokenUser = store.state.roles.permissions.ui_permissions[0].elements;
    // tokenUser = store.getters.tokenUser;
    // console.log(tokenUser);
    //console.log(tokenUser.includes(to.name));
  }
  // console.log(to);
  // console.log(to.meta.parent);
  window.scrollTo(0, 0);
  if (to.path === "/logout") {
    store.commit("logOut");
    next("/login");
  } else {
    if (to.name === "Login") {
      next();
    } else if (jwt === null) {
      next("/login");
    } else if (type !== "agent") {
      // console.log("tokenUser", tokenUser);
      // console.log(
      //   "to.meta",
      //   !tokenUser.some(
      //     (token) => token === to.name || token.includes(to.meta.parent)
      //   ) && !to.meta.isEternal
      // );
      if (
        !tokenUser.some(
          (token) => token === to.name || token.includes(to.meta.parent)
        ) &&
        !to.meta.isEternal
      ) {
        if (tokenUser.some((token) => token === "all")) {
          next();
        } else {
          next("/dashboard");
        }
      } else {
        next();
      }
    } else if (type === "agent") {
      // console.log(admin);
      // console.log(admin.level);
      // console.log(store.getters.admin.level);

      // console.log(to.name);
      if (to.name === "player") {
        next();
      } else if (to.meta.isAgent) {
        next();
      } else if (to.meta.isAgentLevel && store.getters.admin.level == 0) {
        next();
      } else if (to.meta.isAgentSettings) {
        next();
      } else {
        next("/agents");
      }
    } else {
      next();
    }
  }
});
//  router.beforeEach(async (to, from, next) => {
//   let tokenUser
//   if (localStorage.getItem("jwt")) {
//     await store.dispatch("getTokenUser");
//     tokenUser = store.getters.tokenUser;
//     console.log(to);
//     console.log("a ny", tokenUser.includes(to.name));
//   }
//    if (to.path === "/logout") {
//      store.commit("logOut");
//      next("/login");
//    } else {
//      if (to.name === "Login") {
//        next();
//      } else if (!tokenUser) {
//        // Проверяем наличие пользователя в хранилище
//        next("/login");
//      } else if (!tokenUser.includes(to.name)) {
//        next("/dashboard");
//      } else {
//        next();
//      }
//    }
//  });
export default router;
