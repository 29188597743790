import axios from "axios";
export default {
  namespaced: true,
  state: {
    segments: [],
    selectedSegment: {},
  },
  getters: {
    getSegments: (state) => state.segments,
    getSegment: (state) => state.selectedSegment,
  },
  mutations: {
    set(state, { type, items }) {
      state[type] = items;
    },
  },
  actions: {
    async awaitGetSegments({ commit }) {
      if (localStorage.getItem("jwt")) {
        await axios
          .get(`/api/segments/`, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("jwt")}`,
            },
          })
          .then((response) => {
            if (response.status === 200) {
              commit("set", {
                type: "segments",
                items: response.data,
              });
            }
          })
          .catch((error) => {
            console.log(error);
            throw error;
          });
      }
    },
    async getSegmentByID({ commit }, id) {
      if (localStorage.getItem("jwt")) {
        await axios
          .get(`/api/segments/${id}`, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("jwt")}`,
            },
          })
          .then((response) => {
            if (response.status === 200) {
              commit("set", {
                type: "selectedSegment",
                items: response.data,
              });
            }
          })
          .catch((error) => {
            console.log(error);
            throw error;
          });
      }
    },
    async createSegment({}, segment) {
      await axios
        .post(`/api/segments/`, segment, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        })
        .catch((error) => {
          console.log(error);
          throw error;
        });
    },
    async updateSegment({}, segment) {
      await axios
        .patch(`/api/bonuses/deposit/${segment.id}`, segment, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        })
        .catch((error) => {
          console.log(error);
          throw error;
        });
    },
    async deleteSegment({}, id) {
      await axios
        .delete(`/api/segments/${id}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        })
        .catch((error) => {
          console.log(error);
          throw error;
        });
    },
  },
  modules: {},
};
