// notifications.js
import axios from "axios";
const state = {
  notifications: [],
};

const getters = {
  getNotifications: (state) => state.notifications,
};

const mutations = {
  set(state, { type, items }) {
    state[type] = items;
  },
};

const actions = {
  async awaitGetNotificationsByPlayer({ commit }, id) {
    const data = {
      user_id: Number(id),
    };

    const headers = {
      Authorization: `Bearer ${localStorage.getItem("jwt")}`,
    };
    await axios
      .get(`/main-api/notifications/admin/${id}`, { headers })
      .then((response) => {
        const notifData = response.data.map((notif) => ({
          ...notif,
          loadingItem: false,
        }));
        commit("set", { type: "notifications", items: notifData });
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
  },
  async awaitCreateNotificationToPlayer({ commit }, data) {
    const headers = {
      Authorization: `Bearer ${localStorage.getItem("jwt")}`,
      "Content-Type": "application/x-www-form-urlencoded",
    };
    const params = new URLSearchParams();
    for (const key in data) {
      params.append(key, data[key]);
    }
    await axios
      .post(`/main-api/notifications/`, params, { headers })
      .then((response) => {})
      .catch((error) => {
        console.log(error);
        throw error;
      });
  },
  async awaitUpdateNotification({ commit }, data) {
    const headers = {
      Authorization: `Bearer ${localStorage.getItem("jwt")}`,
      "Content-Type": "application/x-www-form-urlencoded",
    };
    const params = new URLSearchParams();
    for (const key in data) {
      params.append(key, data[key]);
    }
    await axios
      .put(`/main-api/notifications/${data.id}`, params, { headers })
      .then((response) => {})
      .catch((error) => {
        console.log(error);
        throw error;
      });
  },
  async awaitDeleteNotification({ commit }, id) {
    const headers = {
      Authorization: `Bearer ${localStorage.getItem("jwt")}`,
    };
    await axios
      .delete(`/main-api/notifications/${id}`, { headers })
      .then((response) => {})
      .catch((error) => {
        console.log(error);
        throw error;
      });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
